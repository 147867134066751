// AntDesign
@import '~antd/dist/antd.css';

// Mixins

$breakpoints: (
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
);

@mixin screen($point) {
  @media (min-width: map-get($breakpoints, $point)) { @content; }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    height: 100%;
    background: #f0f2f5;
    overflow: auto;

    #message-loader {
      width: 100%;
      height: 100%;
      display: flex;
      padding-top: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    #recommande {
      width: 768px;
      margin: auto;

      #logo {
        width: 240px;
        height: 94px;
        background-image: url(./logo-regular.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: auto;
      }

      .header-text {
        padding: 16px 0;

        h3, p {
          margin: auto;
        }
      }

      .users-informations {
        text-align: center;

        .ant-col {
          padding: 12px;
          display: flex;

          .info-container {
            border-radius: 8px;
            border: 3px solid #d7dbde;
            padding: 16px 0 8px;
            background-color: #fdfeff;
            width: 100%;
          }

          .separator {
            height: 2px;
            width: 80px;
            background: #222222;
            border-radius: 10px;
            margin: 0 auto 12px auto;
          }
        }
      }

      #lre-infos {
        border: 3px solid #d7dbde;
        padding: 16px;
        background-color: #fdfeff;
        border-radius: 8px;
        font-size: 1.1em;
        margin-bottom: 12px;
      }

      #lre-content {
        border: 3px solid #d7dbde;
        padding: 16px;
        background-color: #fdfeff;
        border-radius: 8px;
        font-size: 1.1em;
        margin-bottom: 32px;

        #subject {
          background-color: #f0f2f5;
          padding: 16px 16px 4px 16px;
          margin-bottom: 16px;

          p {
            margin-top: 16px;
          }
        }

        #content {
          background-color: #f0f2f5;
          padding: 16px 16px 4px 16px;
          margin-bottom: 16px;

          p {
            margin-top: 16px;
          }
        }

        #attachments {
          background-color: #f0f2f5;
          padding: 16px;

          div:first-child {
            margin-top: 16px;
          }
        }
      }
    }

    #app-loader {
      height: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #login_container {
      max-width: 450px;
      margin: 32px auto auto;
      padding: 16px 32px;
      border-radius: 4px;
      background: #fff;

      #logo {
        width: 240px;
        height: 94px;
        background-image: url(./logo-regular.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin: auto auto 16px;
      }
    }

    .general-loading {
      width: 100%;

      &, .ant-spin-container {
        height: 100%;
      }
    }

    #main-layout {
      height: 100%;

      #side-menu {
        &.ant-layout-sider-below {
          position: fixed;
          height: 100%;
          z-index: 100;
          box-shadow: 0 0 10px rgba(0, 0, 0, .4);

          &:not(.ant-layout-sider-collapsed) + #menu-backdrop {
            display: block;
          }
        }

        #logo {
          height: 64px;
          background-color: rgba(255, 255, 255, 0.95);
          margin-bottom: 16px;

          &::before {
            content: "";
            display: block;
            height: 100%;
            width: 160px;
            margin: auto;
            background-image: url(./logo-regular.png);
            background-size: auto 64px;
            background-repeat: no-repeat;
            background-position: center left;
            transition: ease all .2s;
          }

          &.small::before {
            width: 35px;
            background-position-x: -12px;
          }

          img {
            height: 64px;
          }
        }

        #sign-off {
          background: #e8424b;
          color: #fff;
          position: fixed;
          left: 0;
          bottom: 0;
          margin: 0;
          width: 200px;
          transition: width ease 0.2s;
        }

        &.ant-layout-sider-collapsed #sign-off {
          width: 80px;
        }
      }

      #menu-backdrop {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 90;
        background: rgba(0, 0, 0, .5);
      }

      #header {
        .anticon {
          font-size: 18px;
          line-height: 64px;
          padding: 0 24px;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: #1890ff;
          }
        }
      }

      #content {
        margin: 24px auto 16px !important;
        padding: 24px;
        background: #FFF;
        min-height: 280px;
        flex-shrink: 0;
        max-width: 1400px;
        width: calc(100% - 48px);

        #letter-type-selection {
          margin-top: 40px;
          display: flex;

          .card {
            border: 2px solid #1890ff;
            border-radius: 4px;
            display: flex;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            background: #1890ff;
            color: #ffffff;
            padding: 32px 0 16px;
            height: 100%;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            &.disabled {
              background: #999;
              border-color: #888;
              cursor: default;
            }

            &:not(.disabled):hover {
              box-shadow: 0 0 0 3px #80c2ff;
            }

            .anticon {
              font-size: 50px;
              margin-bottom: 16px;
            }

            .text {
              width: 100%;
              height: 80px;
              // border-top: 1px solid #1890ff;
              display: flex;
              align-items: center;
              padding: 0 16px;

              > div {
                width: 100%;
                text-align: center;
              }
            }
          }
        }

        .ant-form {
          .ant-card {
            .ant-card-body .ant-row.ant-form-item:last-child {
              margin-bottom: 0;
            }

            .ant-alert {
              margin-bottom: 8px;

              &:empty {
                display: none;
              }
            }
          }

          .ant-row.ant-form-item {
            margin-bottom: 16px;
          }

          .ant-form-item-explain {
            display: none;
          }
        }

        .sending-type {
          display: flex;
          flex-direction: column;
          padding: 4px;
          margin: -4px -4px 4px;

          @include screen('md') {
            display: flex;
            flex-direction: row;
          }

          .ant-form-item {
            display: flex;
            margin-bottom: 0;

            &.type-selector {
              flex-grow: 0;
            }

            &.date-picker {
              flex-grow: 1;
              display: none;

              &.visible {
                display: block;
              }

              > div {
                width: 100%;
              }
            }
          }

          .ant-radio-group, .ant-picker {
            width: 100%;

            &.ant-picker {
              margin: 8px 0 0;
            }

            @include screen('md') {
              display: flex;
              width: auto;

              &.ant-radio-group {
                flex-grow: 0;
              }

              &.ant-picker {
                margin: 0 0 0 8px;
                flex-grow: 1;

                > div {
                  width: 100%;
                }
              }
            }
          }
        }

        textarea {
          padding: 4px 11px;
          margin: 0 !important;
          width: 100%;
        }

        .list_container {
          .contact_container {
            &:not(:last-child) {
              margin-bottom: 12px;
            }

            padding-left: 16px;
            border-left: 3px solid #ddd;
          }
        }
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#attachments-body > .ant-card-body {
  padding: 0;

  & > .ant-row {
    margin: 0 !important;
    overflow: hidden;
  }
}

// Popconfirm
.ant-popover {
  z-index: 10000;
}

.table-dropdown-status{
  display: flex;
  width:100%;
  button:first-child{
    flex:1;
    text-align:left;
  }
}

.ant-descriptions-item-label{
  span{
    div{
      svg{
        color: rgba(0,0,0,0.65);
        height: 20px;
        width:20px;
        margin-right: 4px;
      }
    }
  }
}